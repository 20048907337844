import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FooterHelpComponent } from './project-common/components/footer-help/footer-help.component';
import { PreBookComponent } from './project-common/components/pre-book/pre-book.component';
import { StoreNotificationsComponent } from './project-common/components/store-notifications/store-notifications.component';
import { CampaignGuard } from './project-common/services/campaign.guard';
import { CanDeactivateGuardService } from './project-common/services/can-deactivate-guard.service';
import { LogoutHandlerComponent } from './storefront/logout-handler/logout-handler.component';
import { IciciPaymentComponent } from './checkout/icici-payment/icici-payment.component';


const routes: Routes = [
  { path: 'pre-book', component: PreBookComponent },
  { path: 'notifications', component: StoreNotificationsComponent }, //Store Notification route for mobile app
  { path: 'checkout', loadChildren: () => import('../app/checkout/checkout.module').then(m => m.CheckoutModule), canActivate: [CampaignGuard] },
  { path: 'auth', loadChildren: () => import('../app/authentication/authentication.module').then(m => m.AuthenticationModule) },
  { path: 'account', loadChildren: () => import('../app/profile/profile.module').then(m => m.ProfileModule) },
  { path: 'order', loadChildren: () => import('../app/orders/orders.module').then(m => m.OrdersModule) },
  { path: 'support', loadChildren: () => import('../app/support/support.module').then(m => m.SupportModule) },
  { path: 'categories', loadChildren: () => import('../app/modules/category-page/category-page.module').then(m => m.CategoryPageModule) },
  { path: 'search', loadChildren: () => import('../app/modules/search/search.module').then(m => m.SearchModule), data: { title: 'Search results' } },
  { path: 'order_details/:id', redirectTo: 'order/view/:id' }, // Deprecated
  { path: 'order_summary/:id', redirectTo: 'order/success/:id' }, // Deprecated
  { path: 'payment-failure/:orderId', redirectTo: 'order/failed', data: { title: 'Payment failed' } }, // Deprecated
  { path: 'order-payment-success/:orderId', redirectTo: 'order/payment/success', data: { title: 'Order payment success' } },
  { path: 'help/:helpertext', component: FooterHelpComponent, data: { title: 'Kalgudi help' } },
  { path: 'icici_payment', component: IciciPaymentComponent,},
  {
    path: 'rfq',
    loadChildren: () => import('../app/rfq/rfq.module').then(m => m.RfqModule)
  },
  {
    path: '',
    loadChildren: () => import('../app/storefront/storefront.module').then(m => m.StorefrontModule)
  },
  {
    path: 'home',
    redirectTo: '',
    pathMatch: 'full'
  },
  {

    path: 'logout', component: LogoutHandlerComponent

  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    /* Restores the previous scroll position on backward navigation,
      else sets the position to the anchor if one is provided,
      or sets the scroll position to [0, 0] (forward navigation). */
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  providers: [
    CanDeactivateGuardService
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
