import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/project-common/services/spinner.service';
import { CheckoutService } from '../checkout.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'online-payment',
  templateUrl: './online-payment.component.html',
  styleUrls: ['./online-payment.component.scss']
})
export class OnlinePaymentComponent implements OnInit {

  paymentRequest: any = {};


  constructor(
    private spinner: SpinnerService,
    private checkoutService: CheckoutService,
    private translator: TranslateService,
    private snackbar: MatSnackBar,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  onlineTransaction(transactionRequest) {
    this.spinner.start();

    this.checkoutService.postOrderPayload(transactionRequest).subscribe(response => {
      this.doPayment(transactionRequest, response.data);
    }, error => {
      this.spinner.stop();
    })
  }

  newOnlineTransaction(transactionRequest) {
    this.spinner.start();

    this.checkoutService.newPostOrderPayload(transactionRequest).subscribe(response => {
      this.newDoPayment(transactionRequest, response.data);
    }, error => {
      this.spinner.stop();
    })
  }

  iciciOnlineTransaction(transactionRequest) {
    this.spinner.start();

    this.checkoutService.postOrderPayload(transactionRequest).subscribe(response => {
      this.doIciciPayment(transactionRequest, response.data);
    }, error => {
      this.spinner.stop();
    })
  }

  doPayment(transactionRequest, orderId) {
    this.checkoutService.paymentRequest(transactionRequest, orderId).subscribe(response => {
      this.spinner.stop();
      if (response.code === 201 && response.data) {
        this.paymentRequest = JSON.parse(response.data);
        setTimeout(() => {
          document['payUbizForm'].action = this.paymentRequest['url'];;
          document['payUbizForm'].submit();
        }, 300);
      } else {
        this.translator.get(['Please try after some time.', 'OK']).subscribe(translatedText => {
          this.snackbar.open(translatedText['Please try after some time.'], translatedText['OK'], {
            duration: 8000, panelClass: ['snakbar-color']
          });
        })
      }
    }, error => {

    })

  }

  doIciciPayment(transactionRequest, orderId) {
    console.log('test');

    this.checkoutService.iciciPaymentRequest(transactionRequest, orderId).subscribe(response => {
      console.log('res', response);

      this.spinner.stop();
      if (response.code === 200 && response.data) {
        let data = response.data;
        this.router.navigate(['/icici_payment'], { state: {htmlCode: data.requestUrl, orderId: data.masterId, amount: data.amount} });
        // window.open(data.requestUrl, '_self');

      } else {
        this.translator.get(['Please try after some time.', 'OK']).subscribe(translatedText => {
          this.snackbar.open(translatedText['Please try after some time.'], translatedText['OK'], {
            duration: 8000, panelClass: ['snakbar-color']
          });
        })
      }
    }, error => {

    })

  }

  newDoPayment(transactionRequest, orderId) {
    this.checkoutService.newPaymentRequest(transactionRequest, orderId).subscribe(response => {
      this.spinner.stop();
      if (response.code === 201 && response.data) {
        this.paymentRequest = JSON.parse(response.data);
        setTimeout(() => {
          document['payUbizForm'].action = this.paymentRequest['url'];;
          document['payUbizForm'].submit();
        }, 300);
      } else {
        this.translator.get(['Please try after some time.', 'OK']).subscribe(translatedText => {
          this.snackbar.open(translatedText['Please try after some time.'], translatedText['OK'], {
            duration: 8000, panelClass: ['snakbar-color']
          });
        })
      }
    }, error => {

    })

  }



}
