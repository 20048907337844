
<footer>

    <section class="common-footer">
        <div class="app-container" [hidden]="hideFooter">
            <div class="footer-sec-1" fxLayout="row wrap">
                <div class="footer-sub-sec-1" fxFlex="48%" fxFlex.xs="100%">
                    <div  class="w-100 d-flex flex-column">
                        <a class="d-flex flex-column align-items-start logo-sec" [href]="commonFooterData?.footerObject1?.url">
                            <div class="d-flex align-items-center">
                                <img  [src]="commonFooterData?.footerObject1?.logo" 
                                class="kl-logo" alt="Logo" width="42px" height="40px">
                                <span class="text-uppercase store-name " translate>{{commonFooterData?.footerObject1?.name}}</span>
                            </div>
                            <div class="tag-line text-uppercase" translate>
                                {{commonFooterData?.footerObject1?.tagLine}}
                            </div>
                        </a>
        
                        <p translate>{{commonFooterData?.footerObject1?.storeInfo}}</p>
                        <br>
    
                        <div class="d-flex justify-content-between store-names">
                            <div class="d-flex flex-column" *ngIf="commonFooterData?.footerObject1?.storesList">
                                <h1 class="text-uppercase" translate>STORES</h1>
                                <ng-container *ngFor="let store of commonFooterData?.footerObject1?.storesList">
                                    <a [href]="store?.url" target="_blank" translate>{{store?.storeName}}</a>
                                </ng-container>
                            </div>
                            <div class="d-flex flex-column" *ngIf="commonFooterData?.footerObject1?.shop" fxFlex.xs="40%">
                                <h1 class="text-uppercase" translate>SHOP</h1>
                                <ng-container>
                                    <ng-container *ngFor="let store of commonFooterData?.footerObject1?.shop">
                                        <a [href]="store?.url" target="_blank" translate>{{store?.name}}</a>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div class="d-flex flex-column contact-sec">
                                <div *ngIf="false" class="d-flex align-items-center mb-10">
                                    <img  src="{{commonFooterData?.footerObject1?.call?.image}}" loading="lazy"
                                    class="dial-icon" alt="Logo">
                                    <span class="text-uppercase logo-info" translate>{{commonFooterData?.footerObject1?.call?.value}}</span>
                                </div>
    
                                <div class="d-flex align-items-center mb-10">
                                    <img  src="{{commonFooterData?.footerObject1?.emailTo?.image}}" loading="lazy"
                                    class="dial-icon" alt="Logo">
                                    <span class="logo-info" translate>{{commonFooterData?.footerObject1?.emailTo?.value}}</span>
                                </div>
    
                                <!-- <div class="d-flex flex-column">
                                    <div class="mb-10 follow-us">Follow us</div>
                                    <div fxLayoutGap="5px">
                                        <a *ngFor="let x of commonFooterData?.footerObject1?.followUs" [href]="x?.url">
                                            <img src="{{x?.image}}">
                                        </a>
                                    </div>
                                </div> -->
                            </div>
                        </div>

                        <div class="d-flex flex-column contact-sec-xs">
                            <div class="d-flex align-items-center mb-10">
                                <img  src="{{commonFooterData?.footerObject1?.call?.image}}" loading="lazy"
                                class="dial-icon" alt="Logo">
                                <span class="text-uppercase logo-info" translate>{{commonFooterData?.footerObject1?.call?.value}}</span>
                            </div>

                            <div class="d-flex align-items-center mb-10">
                                <img  src="{{commonFooterData?.footerObject1?.emailTo?.image}}" loading="lazy"
                                class="dial-icon" alt="Logo">
                                <span class="logo-info" translate>{{commonFooterData?.footerObject1?.emailTo?.value}}</span>
                            </div>

                            <!-- <div class="d-flex flex-column">
                                <div class="mb-10 follow-us">Contact Us</div>
                                <div fxLayoutGap="5px">
                                    <a *ngFor="let x of commonFooterData?.footerObject1?.followUs" [href]="x?.url">
                                        <img src="{{x?.image}}">
                                    </a>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    
                </div>
                <div class="footer-sub-sec-2" fxFlex="52%" fxFlex.xs="100%">
                    <div  class="w-100 d-flex flex-column">
                        <a class="d-flex flex-column align-items-start logo-sec" [href]="commonFooterData?.footerObject2?.url" target="_blank">
                            <div class="d-flex align-items-center">
                                <img  src="{{commonFooterData?.footerObject2?.logo}}" loading="lazy"
                                class="kl-logo" alt="Logo" width="42px" height="40px">
                                <span class="text-uppercase store-name " translate>{{commonFooterData?.footerObject2?.name}}</span>
                            </div>
                            <div class="tag-line text-uppercase">
                                {{commonFooterData?.footerObject2?.tagLine}}
                            </div>
                        </a>
        
                        <p translate>{{commonFooterData?.footerObject2?.storeInfo}}</p>
    
                            <div class="d-flex justify-content-between">
                                <div class="d-flex flex-column">
                                    <h1 class="text-uppercase" translate>Stores</h1>
                                    <ng-container *ngFor="let store of commonFooterData?.footerObject2?.storesList">
                                        <a [href]="store?.url" target="_blank" translate>{{store?.storeName}}</a>
                                    </ng-container>
                                </div>
                                <div class="d-flex flex-column" fxFlex.xs="40%">
                                    <h1 class="text-uppercase">Apps</h1>
                                    <ng-container *ngFor="let store of commonFooterData?.footerObject2?.shop">
                                        <a [href]="store?.url" target="_blank" translate>{{store?.name}}</a>
                                    </ng-container>
                                </div>
                                <div class="d-flex flex-column contact-sec">
                                    <div *ngIf="false" class="d-flex align-items-center mb-10">
                                        <img  src="{{commonFooterData?.footerObject2?.call?.image}}" loading="lazy"
                                        class="dial-icon" alt="Logo">
                                    <span class="logo-info" translate>{{commonFooterData?.footerObject2?.call?.value}}</span>
                                    </div>
    
                                    <div class="d-flex align-items-center mb-10">
                                        <img  src="{{commonFooterData?.footerObject2?.emailTo?.image}}" loading="lazy"
                                        class="dial-icon" alt="Logo">
                                        <span class="logo-info" translate>{{commonFooterData?.footerObject2?.emailTo?.value}}</span>
                                    </div>
    
                                    <div class="d-flex flex-column">
                                        <!-- <div class="mb-10 follow-us" translate>Contact Us</div> -->
                                        <div fxLayoutGap="5px">
                                            <a *ngFor="let x of commonFooterData?.footerObject2?.followUs" [href]="x?.url" target="_blank">
                                                <img src="{{x?.image}}" loading="lazy">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex flex-column contact-sec-xs">
                                <div class="d-flex align-items-center mb-10">
                                    <img  src="{{commonFooterData?.footerObject2?.call?.image}}" loading="lazy"
                                    class="dial-icon" alt="Logo">
                                <span class="logo-info" translate>{{commonFooterData?.footerObject2?.call?.value}}</span>
                                </div>

                                <div class="d-flex align-items-center mb-10">
                                    <img  src="{{commonFooterData?.footerObject2?.emailTo?.image}}" loading="lazy"
                                    class="dial-icon" alt="Logo">
                                    <span class="logo-info" translate>{{commonFooterData?.footerObject2?.emailTo?.value}}</span>
                                </div>

                                <div class="d-flex flex-column">
                                    <div class="mb-10 follow-us">Follow us</div>
                                    <div fxLayoutGap="5px">
                                        <a *ngFor="let x of commonFooterData?.footerObject2?.followUs" [href]="x?.url" target="_blank">
                                            <img src="{{x?.image}}" loading="lazy">
                                        </a>
                                    </div>
                                </div>
                            </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="footer-sec-2" [class.fixed-bottom]="isMobileDevice && hideFooter">
            <div class="app-container" fxLayout="row" 
            fxLayoutAlign="space-between center" 
            fxLayout.xs="column" fxLayoutAlign.xs="center start">
                <div translate>Powered by Kalgudi</div>
                <div translate>Copyright 2021-2022</div>
            </div>
        </div>
    </section>
</footer>
