import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KalgudiImagePickerModule, KalgudiSellerPickerModule } from '@kalgudi/common';
import { KalgudiGooglePlacesModule } from '@kalgudi/third-party/google-places';
import { TranslateModule } from '@ngx-translate/core';

import { GooglePlacesModule } from '../modules/google-places/google-places.module';
import { ConfirmDialogComponent } from '../project-common/components/confirm-dialog/confirm-dialog.component';
import { ProjectCommonModule } from './../project-common/project-common.module';
import { AssistanceComponent } from './assistance/assistance.component';
import { CartItemComponent } from './cart/cart-item/cart-item.component';
import { CartComponent } from './cart/cart.component';
import { CheckoutRoutingModule } from './checkout-routing.module';
import { CheckoutTitleComponent } from './checkout-title/checkout-title.component';
import { CheckoutComponent } from './checkout.component';
import { DeliveryDiscountComponent } from './delivery-discount/delivery-discount.component';
import { DeliveryMessageDialogComponent } from './delivery-message-dialog/delivery-message-dialog.component';
import { ItemComponent } from './item/item.component';
import { ItemsConfirmDialogComponent } from './items-confirm-dialog/items-confirm-dialog.component';
import { OnlinePaymentComponent } from './online-payment/online-payment.component';
import { PaymentConformationDilogComponent } from './payment-conformation-dilog/payment-conformation-dilog.component';
import { PaymentComponent } from './payment/payment.component';
import { PriceUpdateDialogComponent } from './payment/price-update-dialog/price-update-dialog.component';
import { SavedItemComponent } from './saved-item/saved-item.component';
import { ShippingComponent } from './shipping/shipping.component';
import { SummaryComponent } from './summary/summary.component';
import { GiftMessageDialogComponent } from './gift-message-dialog/gift-message-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { ConfirmOrderDialogComponent } from './confirm-order-dialog/confirm-order-dialog.component';
import { IciciPaymentComponent } from './icici-payment/icici-payment.component';

@NgModule({
  imports: [
    CommonModule,
    CheckoutRoutingModule,
    ProjectCommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    GooglePlacesModule,

    MatCheckboxModule,

    KalgudiButtonModule,

    KalgudiSellerPickerModule,
    KalgudiImagePickerModule,

    KalgudiGooglePlacesModule
  ],
  declarations: [
    CheckoutComponent, 
    CartComponent, 
    ShippingComponent, 
    PaymentComponent, 
    PriceUpdateDialogComponent, 
    CartItemComponent, 
    DeliveryDiscountComponent, 
    ItemComponent, 
    SummaryComponent, 
    CheckoutTitleComponent, 
    SavedItemComponent,
    PaymentConformationDilogComponent,
    OnlinePaymentComponent,
    DeliveryMessageDialogComponent,
    ItemsConfirmDialogComponent,
    AssistanceComponent,
    GiftMessageDialogComponent,
    ConfirmOrderDialogComponent,
    IciciPaymentComponent,
  ],
  exports: [PriceUpdateDialogComponent],
  entryComponents: [
    PriceUpdateDialogComponent,
    ConfirmDialogComponent, 
    PaymentConformationDilogComponent, 
    DeliveryMessageDialogComponent, 
    ItemsConfirmDialogComponent,
    GiftMessageDialogComponent,
    ConfirmOrderDialogComponent
  ]
  
})
export class CheckoutModule { }
